#menu .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

#menu .container .left {
    width: 15%;
}

#menu .container .text1,
#menu .container .text2 {
    color: #5E2985;
    font-weight: 400;
}

#menu .container .text1 b,
#menu .container .text2 b {
    font-weight: bold;
}

#menu .container .text1 {
    width: 28%;
}

#menu .container .text2 {
    display: flex;
    flex-direction: column;
}

#menu .container .link {
    display: flex;
    align-items: center;
    gap: .5rem;
}

#menu .container .link figure {
    width: 10%;
    margin-top: .3rem;
}

#menu .container .link a {
    color: #441268;
}

#menu .container .mobile {
    display: none;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container .text1 {
        display: none;
    }

    #menu .container .left {
        width: 100%;
        text-align: left;
    }

    #menu .container .left img {
        width: 50%;
    }

    #menu .container .text1,
    #menu .container .text2,
    #menu .container .link {
        display: none;
    }

    #menu .container .mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    #menu .container .mobile .text {
        color: #5E2985;
        font-weight: 400;
    }

    #menu .container .mobile .bottom {
        display: flex;
        gap: 1rem;
    }

    #menu .container .mobile .bottom .link {
        display: flex;
        align-items: center;
        gap: .5rem;
    }
    
    #menu .container .mobile .bottom .link figure {
        width: 15%;
        margin-top: .3rem;
    }
    
    #menu .container .mobile .bottom .link a {
        color: #441268;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container .left {
        width: 50%;
    }

    #menu .container .left img {
        width: 70%;
    }

    
    #menu .container .mobile .bottom .link {
        font-size: .7rem;
    }
}