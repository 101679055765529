#about .about1 {
  background: url("../../assets/about1.png") no-repeat center center;
  background-size: cover;
  height: 90vh;
}

#about .about1 .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#about .about1 .container .left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  color: white;
  width: 40%;
}

#about .about1 .container .left .title {
  color: white;
  text-transform: initial;
}

#about .about1 .container .left .text {
  font-weight: 500;
}

#about .about1 .container .left #ctaWpp {
  margin-right: auto;
}

#about .about1 .container .right {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

#about .about1 .container .right li {
  display: flex;
  align-items: center;
  gap: .5rem;
}

#about .about1 .container .right li figure {
  width: 10%;
  margin-top: .4rem;
}

#about .about1 .container .right p {
  color: #441268;
  font-weight: bold;
  font-size: 1.2rem;
}

#about .about1 .container .mobile {
  display: none;
}

#about .about2 .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#about .about2 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

#about .about2 .container .left .title {
  text-transform: initial;
}

#about .about2 .container .left #ctaWpp {
  margin-right: auto;
}

#about .about2 .container .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .right .title {
  display: none;
}

#about .about3 {
  background-color: #E4E9F6;
}

#about .about3 .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#about .about3 .container .left .title {
  display: none;
}

#about .about3 .container .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

#about .about3 .container .right .title {
  text-transform: initial;
}

#about .about3 .container .right .title span {
  text-transform: uppercase;
}

#about .about3 .container .right #ctaWpp {
  margin-right: auto;
}

#about .about4 .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#about .about4 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50%;
}

#about .about4 .container .left .title {
  text-transform: initial;
}

#about .about4 .container .right .title {
  display: none;
}

#about .about4 .container .left #ctaWpp {
  margin-right: auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 {
    background: url("../../assets/mobile/about1.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
  }

  #about .about1 .container {
    flex-direction: column;
    justify-content: flex-start;
  }

  #about .about1 .container .left {
    margin-top: -25rem;
    text-align: center;
    width: 100%;
  }

  #about .about1 .container .left #ctaWpp {
    display: none;
  }

  #about .about1 .container .right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-top: 10rem;
  }

  #about .about1 .container .mobile {
    display: block;
    margin: 2rem auto;
  }

  #about .about2 .container {
    flex-direction: column;
    gap: 2rem;
  }

  #about .about2 .container .left {
    width: 100%;
    text-align: center;
  }

  #about .about2 .container .left .title {
    display: none;
  }

  #about .about2 .container .left #ctaWpp {
    margin: 0 auto;
  }

  #about .about2 .container .right {
    order: -1;
  }

  #about .about2 .container .right .title {
    display: block;
    text-transform: initial;
  }

  #about .about3 .container {
    flex-direction: column;
    gap: 2rem;
  }

  #about .about3 .container .left {
    order: -1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  #about .about3 .container .left .title {
    display: block;
    text-transform: initial;
  }

  #about .about3 .container .right {
    width: 100%;
    text-align: center;
  }

  #about .about3 .container .right .title {
    display: none;
  }

  #about .about3 .container .right #ctaWpp {
    margin: 0 auto;
  }

  #about .about4 .container {
    flex-direction: column;
    gap: 2rem;
  }

  #about .about4 .container .left {
    width: 100%;
    text-align: center;
  }

  #about .about4 .container .left .title {
    display: none;
  }

  #about .about4 .container .left #ctaWpp {
    margin: 0 auto;
  }

  #about .about4 .container .right {
    order: -1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  #about .about4 .container .right .title {
    display: block;
    text-transform: initial;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container {
    height: 100%;
  }

  #about .about1 .container .left {
    margin-top: -2rem;
    gap: 1rem;
  }

  #about .about1 .container .right {
    margin-top: 12rem;
  }
}