#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
}

#header .header1 .container #ctaWpp {
    margin-top: auto;
}

#header .mobile {
    display: none;
}

#header .header2 {
    background-color: #E4E9F6;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}

#header .header2 .container .subtitle {
    font-size: 1.2rem;
    font-weight: 500;
}

#header .header2 .container .text {
    color: #441268;
    font-weight: bold;
    font-size: 1.2rem;
}

#header .header2 .container .carousel {
    width: 100%;
}

#header .header2 .container #ctaWpp {
    margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 60vh;
    }

    #header .header1 .container #ctaWpp {
        margin: auto auto 0;
    }

    #header .mobile {
        display: block;
        text-align: center;
        color: #441268;
        font-weight: 400;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 47vh;
    }

}