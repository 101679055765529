#footer .footer1 {
    background-color: #230837;
}

#footer .footer1 .container {
    display: flex;
    justify-content: space-between;
    color: white;
}

#footer .footer1 .container figure {
    width: 15%;
}

#footer .footer1 .container .text1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 25%;
    line-height: 1.5rem;
}

#footer .footer1 .container .text1 .bottom {
    font-weight: 400;
    display: flex;
    flex-direction: column;
}

#footer .footer1 .container .text2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#footer .footer1 .container .text2 .top {
    font-weight: 400;
}

#footer .footer1 .container .text2 .bottom {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#footer .footer1 .container .text2 .bottom li {
    display: flex;
    align-items: center;
    gap: .2rem;
}

#footer .footer1 .container .text2 .bottom li figure {
    width: 8%;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container {
        flex-direction: column;
        gap: 2rem;
    }

    #footer .footer1 .container figure {
        width: 100%;
    }

    #footer .footer1 .container figure img {
        width: 30%;
    }
    
    #footer .footer1 .container .text1 {
        width: 100%;
    }

    #footer .footer1 .container .text1 .bottom {
        width: 60%;
    }
    
    #footer .footer2 .container {
        flex-direction: column;
        gap: 1rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #footer .footer1 .container .text2 .bottom li figure {
        width: 14%;
    }
}