#content .content1 {
  background: url("../../assets/content1.png") no-repeat center center;
  background-size: cover;
  height: 90vh;
}

#content .content1 .container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 40%;
  color: white;
  margin-left: 10vw;
  height: 85%;
}

#content .content1 .container .title {
  color: white;
}

#content .content1 .container .text2 {
  font-weight: 400;
  width: 80%;
}

#content .content1 .container #ctaWpp {
  margin-right: auto;
  margin-top: auto;
}

#content .content1 .container .mobile {
  display: none;
}

#content .content2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: -7rem;
}

#content .content2 .container .gallery {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#content .content2 .container .gallery .item {
  background-color: #E4E9F6;
  padding: 2rem;
  border-radius: 7px;
  color: #551173;
  text-align: center;
  width: 100%;
}

#content .content2 .container .gallery .item figure {
  margin-bottom: 1rem;
}

#content .content2 .container .gallery .item figure img {
  width: 40%;
}

#content .content2 .container .gallery .item .text1 {
  font-size: 4rem;
}

#content .content2 .container #ctaWpp a figure {
  width: 3%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .content1 {
    background: url("../../assets/mobile/content1.png") no-repeat center;
    background-size: cover;
    height: 100%;
  }

  #content .content1 .container {
    width: 90%;
    margin-left: 7.5vw;
    text-align: center;
  }

  #content .content1 .container .text2 {
    width: 90%;
    margin: 0 auto;
  }

  #content .content1 .container #ctaWpp {
    display: none;
  }
  
  #content .content1 .container .mobile {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  #content .content1 .container .mobile .gallery {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  #content .content1 .container .mobile .gallery .item {
    background-color: #E4E9F6;
    padding: 1rem;
    border-radius: 7px;
    color: #551173;
    text-align: center;
    width: 100%;
  }
  
  #content .content1 .container .mobile .gallery .item figure {
    margin-bottom: 1rem;
  }
  
  #content .content1 .container .mobile .gallery .item figure img {
    width: 40%;
  }
  
  #content .content1 .container .mobile .gallery .item .text1 {
    font-size: 3rem;
  }
  
  #content .content1 .container .mobile #ctaWpp {
    display: block;
    margin: 0 auto;
  }

  #content .content1 .container .mobile #ctaWpp a figure {
    width: 5%;
  }
  
  #content .content2 .container {
    display: none;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .content1 .container .mobile .gallery {
    flex-direction: column;
    gap: 2rem;
  }

  #content .content1 .container {
    width: 90%;
    margin-left: 5vw;
  }

  #content .content1 .container .text2 {
    width: 100%;
  }

  #content .content1 .container .mobile #ctaWpp a figure {
    width: 5%;
  }
}